import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { FaArrowRight, FaArrowLeft } from 'react-icons/fa';
import './banner.css';

const Banner = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const numSlides = 3; // Number of slides

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveIndex((prevIndex) => (prevIndex === numSlides - 1 ? 0 : prevIndex + 1));
    }, 10000); // Change slide every 10 seconds (adjust as needed)

    return () => clearInterval(interval);
  }, []);

  const handleNextClick = () => {
    setActiveIndex((prevIndex) => (prevIndex === numSlides - 1 ? 0 : prevIndex + 1));
  };

  const handlePrevClick = () => {
    setActiveIndex((prevIndex) => (prevIndex === 0 ? numSlides - 1 : prevIndex - 1));
  };

  return (
    <div id="carouselExampleIndicators" className="carousel slide carousel-fade" data-bs-ride="carousel">
      <div className="carousel-inner">
        <div className={`carousel-item ${activeIndex === 0 ? 'active' : ''}`}>
          <img src="/images/2.png" className="d-block w-100" alt="Camera" />
        </div>
        <div className={`carousel-item ${activeIndex === 1 ? 'active' : ''}`}>
          <img src="/images/1.png" className="d-block w-100" alt="Exotic Fruits" />
        </div>
        <div className={`carousel-item ${activeIndex === 2 ? 'active' : ''}`}>
          <img src="/images/3.png" className="d-block w-100" alt="Nature" />
        </div>
      </div>
      <button className="carousel-control-prev custom-control" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev" onClick={handlePrevClick}>
        <FaArrowLeft />
        <span className="visually-hidden">Previous</span>
      </button>
      <button className="carousel-control-next custom-control" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next" onClick={handleNextClick}>
        <FaArrowRight />
        <span className="visually-hidden">Next</span>
      </button>
    </div>
  );
};

export default Banner;
