import React from 'react';
import './not-found.scss';  // Make sure to adjust the import statement based on your actual CSS file location

const NotFound = () => {
  return (
    <div className="not-found">
        <p>404</p>
        <h1>Page Not Found</h1>
        <a href='/'><button>Continue Shopping</button></a>
    </div>
  );
};

export default NotFound;