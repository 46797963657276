import React, { useState, useEffect } from 'react';
import './refund-policy.scss';

const RefundPolicy = () => {
  

  return (
    <div className="refund-policy">
      <div className='container'>
    
      <h1>Refund Policy</h1>
      <p>Thank you for shopping at Emtech Industrial Supply. We want to ensure that you are completely satisfied with your purchase. This Refund Policy outlines our procedures for returns, exchanges, and refunds.</p>
    
      <ul>
        <li>
          <h6>Eligibility for Refunds:</h6>
          <p>To be eligible for a refund, your item must be unused, in its original condition, and returned within 15 days of received. Items that are damaged, worn, or not in their original packaging may not be eligible for a refund.</p>
        </li>
        <li>
          <h6>Refund Process:</h6>
          <p>To request a refund, please contact our customer service team at aarzolac@gmail.com with your order number and reason for return. Once your return request is approved, you will receive instructions on how to return the item. Please note that return shipping costs are the responsibility of the customer.</p>
        </li>
        <li>
          <h6>Refund Method:</h6>
          <p>Refunds will be issued to the original payment method used for the purchase. Depending on your financial institution, it may take up to 7 days for the refund to appear in your account.</p>
        </li>
        <li>
          <h6>Return Shipping:</h6>
          <p>When returning an item, please ensure that it is securely packaged and includes the original packing slip or order confirmation. We recommend using a trackable shipping method to ensure that your return is received safely. We are not responsible for items lost or damaged during return shipping.</p>
        </li>
        <li>
          <h6>Contact Us:</h6>
          <p>If you have any questions or concerns about our Refund Policy or the status of your return, please contact us at aarzolac@gmail.com. Our customer service team is here to assist you and ensure a smooth refund process.</p>
        </li>
      </ul>
      </div>
      
    </div>
  );
};

export default RefundPolicy;