import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Banner from '../../components/banner/banner';
import FeaturedProducts from '../../components/featuredProducts/featuredProducts'
import Categories from '../../components/categories/categories';

const Home = () => {
  

  return (
    <div className="home">
      <Banner />
      <FeaturedProducts type="Featured"/>
      <Categories/>
      <FeaturedProducts type="Trending"/>

      
    </div>
  );
};

export default Home;