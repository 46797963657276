import React, { useState } from 'react';
import { InstantSearch, InfiniteHits, Configure, connectSearchBox } from 'react-instantsearch-dom';
import { instantMeiliSearch } from '@meilisearch/instant-meilisearch';
import { useNavigate, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import './search-bar.scss';

// MeiliSearch client setup
const { searchClient } = instantMeiliSearch(
  'https://search.emtechindustrialsupply.com',
  'nPbdxpjJiSZyWKpJsXx45JnGhHczGAyEO4jjzstAXX0',
);

// Custom search box component
const CustomSearchBox = ({ currentRefinement, refine, onSearchSubmit }) => {
  const navigate = useNavigate();

  const handleSubmit = (event) => {
    event.preventDefault();
    
    if (currentRefinement.trim() === '') {
      window.location.reload(); // Refresh the page if input is empty
    } else {
      onSearchSubmit(); // Call the function to change visibility
      navigate(`/search-results?query=${encodeURIComponent(currentRefinement)}`);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="search-form">
      <input
        type="search"
        value={currentRefinement}
        onChange={event => refine(event.currentTarget.value)}
        className="searchBox"
        placeholder="Search..."
      />
      <button type="submit" className="search-submit-button">
        <FontAwesomeIcon icon={faSearch} />
      </button>
    </form>
  );
};

// Connect CustomSearchBox to InstantSearch
const SearchBoxWithState = connectSearchBox(CustomSearchBox);

// Hit component for rendering search results
const Hit = ({ hit }) => {
  console.log('Hit data:', hit);

  const imageUrl = `https://api.emtechindustrialsupply.com${hit.img?.url}`;
  
  return (
    <li className="hits" key={hit.id}>
      <a href={`/products/${hit.product_name}`} className="hit-link">
        <img src={imageUrl} className="hit-image" alt={hit.title} />
        <p className="hit-title">{hit.title}</p>
      </a>
    </li>
  );
};

// Main Search component
const Search = () => {
  const [isVisible, setIsVisible] = useState(true);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const query = queryParams.get('query') || '';

  const handleSearchSubmit = () => {
    setIsVisible(false);
  };

  return (
    <div className="search-container" style={{ display: isVisible ? 'block' : 'none' }}>
      <h3>Search</h3>
      <InstantSearch
        indexName="product"
        searchClient={searchClient}
      >
        <Configure hitsPerPage={5} query={query} />
        <SearchBoxWithState onSearchSubmit={handleSearchSubmit} />
        <InfiniteHits hitComponent={Hit} />
      </InstantSearch>
    </div>
  );
};

export default Search;
