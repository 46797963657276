import React from 'react';
import './about us.scss';  // Make sure to adjust the import statement based on your actual CSS file location

const About = () => {
  return (
    <div className="about-container">
      <h1>About Us</h1>
      <div className="content-container">
        <div className="image-container">
          <img src="/images/about-us.jpg" alt="About Us" />
        </div>
        <div className="text-container">
          <p>
             Welcome to Emtech Industrial Supply, where we specialize in providing top-tier electronic industrial equipment to meet the diverse needs of businesses and individuals in the industrial sector. Our mission is simple: to empower our customers with the tools and machinery they need to thrive in today's rapidly evolving industrial landscape. From electronic components and parts to cutting-edge automation systems, we offer a comprehensive range of high-quality products sourced from trusted manufacturers. What sets us apart is our commitment to customer satisfaction, competitive pricing, and expert guidance. Whether you're a small business or a large corporation, we're here to serve you with efficiency and dedication. Contact us today at aarzolac@gmail.com to learn more about our offerings and experience the difference with Emtech Industrial Supply.
          </p>
        </div>
      </div>
    </div>
  );
};

export default About;