
import React, { useState, useEffect } from 'react';
import './privacy-policy.scss'

const PrivacyPolicy = () => {
  

  return (
    <div className="privacy-policy">
      <div className='container'>
        <h1>Privacy Policy</h1>
        <p>
          At Emtech Industrial Supply, we are committed to protecting your privacy and ensuring the security of your personal information. This Privacy Policy outlines how we collect, use, disclose, and protect the information you provide when you use our website or make purchases through our platform. By accessing or using our services, you agree to the terms outlined in this policy.

         </p>
         <ul>
            <li>
              Information We Collect:
              <p>We may collect personal information such as your name, email address, shipping address, billing information, and payment details when you register an account, make a purchase, or subscribe to our newsletter. Additionally, we may automatically collect certain non-personal information such as your IP address, browser type, device identifiers, and browsing activity through cookies and similar technologies.</p>
            </li>
            <li>
              Data Security:
              <p>We take reasonable measures to protect the confidentiality and security of your personal information. We implement technical, administrative, and physical safeguards to prevent unauthorized access, disclosure, alteration, or destruction of your data. However, no method of transmission over the internet or electronic storage is 100% secure, so we cannot guarantee absolute security.</p>
            </li>
            <li>
              Third-Party Services:
              <p>We may use third-party service providers to assist us in operating our website, processing payments, and delivering products to you. These service providers may have access to your personal information only to perform tasks on our behalf and are obligated not to disclose or use it for any other purpose.</p>
            </li>
            <li>
              Updates to This Privacy Policy:
              <p>We may update this Privacy Policy from time to time to reflect changes in our practices or legal requirements. We will notify you of any material changes by posting the updated policy on our website or sending you an email. Your continued use of our services after the changes are made constitutes your acceptance of the revised policy.</p>
            </li>
            <li>
              Contact Us:
              <p>If you have any questions or concerns about our Privacy Policy or our handling of your personal information, please contact us at aarzolac@gmail.com. We are committed to resolving any issues and ensuring your privacy rights are protected.</p>
            </li>
          </ul>
      </div>    
    </div>
  );
};

export default PrivacyPolicy;