import React, { useState, useEffect } from 'react';
import './shipping-policy.scss';

const ShippingPolicy = () => {
  

  return (
    <div className="shipping-policy">
      <div className='container'>

        <h1>Shipping Policy</h1>
   
        <p>Thank you for shopping at Emtech Industrial Supply. This Shipping Policy outlines our shipping procedures, delivery times, and related information to ensure a smooth shopping experience.</p>
        <ul>
          <li>
            <h6>Processing Time:</h6>
            <p>Orders are typically processed within 1-3 business days after payment confirmation. During peak seasons or promotional periods, processing times may be slightly longer, but we strive to fulfill orders as quickly as possible.</p>
          </li>
          <li>
            <h6>Shipping Methods:</h6>
            <p>We offer several shipping options to accommodate your needs. Available shipping methods may include standard shipping, expedited shipping, and express shipping. The shipping costs and delivery times vary depending on your location and the selected shipping method.</p>
          </li>
          <li>
            <h6>Delivery Time</h6>
            <p>Delivery times vary depending on the shipping method chosen and your location. Standard shipping typically takes 3-7 business days for domestic orders and 7-21 business days for international orders. Expedited and express shipping options are available for faster delivery.</p>
          </li>
          <li>
            <h6>Order Tracking</h6>
            <p>Once your order has been shipped, you will receive a confirmation email with a tracking number. You can use this tracking number to monitor the status of your shipment and estimated delivery date. Please note that tracking information may take up to 24 hours to become available.</p>
          </li>
          <li>
           <h6>  Shipping Restrictions:</h6>
            <p>Some items may be subject to shipping restrictions due to size, weight, or regulatory requirements. We do not ship to P.O. boxes, APO/FPO addresses, or international destinations where prohibited by law.</p>
          </li>
          <li>
            <h6>Shipping Costs:</h6>
            <p>Shipping costs are calculated based on the weight of your order, shipping destination, and selected shipping method. The shipping charges will be displayed at checkout before you confirm your order.</p>
          </li>
          <li>
            <h6>Shipping Changes or Cancellations:</h6>
            <p>If you need to make changes to your shipping address or cancel your order after it has been placed, please contact us as soon as possible. Once an order has been shipped, we are unable to make any changes or cancellations.</p>
          </li>
          <li>
            <h6>Contact Us:</h6>
            <p>If you have any questions or concerns about our Shipping Policy or the status of your order, please contact us at aarzolac@gmail.com. Our customer service team is here to assist you and ensure your satisfaction.</p>
          </li>
        </ul>

      </div>
    </div>
  );
};

export default ShippingPolicy;