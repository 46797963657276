import React from 'react';
import './productCard.scss';
import { Link } from 'react-router-dom';

const ProductCard = ({ item }) => {
  const productName = item?.attributes.product_name;

  // Function to convert product name to URL-friendly format
  const formatProductNameToUrl = (productName) => {
    if (!productName) return ""; // Check if productName is undefined or null
    return productName.toLowerCase().replace(/\s+/g, '-');
  };
  
  return (
    <Link className='link' to={`/products/${formatProductNameToUrl(productName)}`} style={{ textDecoration: 'none' }}>
      <div className='card-container'>
        <div className='card2'>
          <a href="/product" className="card-link">
            <div className='image'>
              <img src={process.env.REACT_APP_UPLOAD_URL + item.attributes?.img?.data?.attributes?.url} alt="" className='mainImg'/>
            </div>
          </a>
          <p className='title'>{item?.attributes.title}</p>
          <p className='price'>${item?.attributes.price}</p>
          
        </div>
      </div>
    </Link>
  );
};

export default ProductCard;