import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faMapMarkerAlt, faPhone } from '@fortawesome/free-solid-svg-icons';
import emailjs from 'emailjs-com';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import './contact.css'; // Adjust the import statement based on your actual CSS file location

const Contact = () => {
  const backgroundImageUrl = '/images/contact.jpg';

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();

    const templateParams = {
      name,
      email,
      message,
    };

    emailjs.send('service_u0xc9ug', 'template_8hhbytt', templateParams, 'N6LGzmJkOmTIxT7CV')
      .then((response) => {
        toast.success('Message sent successfully we will contact you as soon as possible', {
          className: 'custom-toast-success',
        });
        setName('');
        setEmail('');
        setMessage('');
      }, (err) => {
        toast.error('Failed to send the message. Please try again.');
      });
  };

  return (
    <div className='contact-page'>
      <div className='left-contact' style={{ backgroundImage: `url(${backgroundImageUrl})` }}>
        <div className="contact-info">
          <div className="contact-icon">
            <FontAwesomeIcon icon={faEnvelope} />
            <p className="contact-text">aarzolac@gmail.com</p>
          </div>
          <div className="contact-icon">
            <FontAwesomeIcon icon={faMapMarkerAlt} />
            <p className="contact-text">2000 Oriole Ave, McAllen Texas</p>
          </div>
          <div className="contact-icon">
            <FontAwesomeIcon icon={faPhone} />
            <p className="contact-text">(956) 330-2103</p>
          </div>
        </div>
      </div>
      <div className='right-contact'>
        <form onSubmit={handleSubmit} className="contact-form">
          <h2>Contact Us</h2>
          <label htmlFor="name">Name:</label>
          <input
            type="text"
            id="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />

          <label htmlFor="email">Email:</label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />

          <label htmlFor="message">Message:</label>
          <textarea
            id="message"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            required
          ></textarea>

          <button type="submit">Submit</button>
        </form>
      </div>
      <ToastContainer />
    </div>
  );
};

export default Contact;