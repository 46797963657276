import React, { useState} from "react";
import { useParams, Link } from "react-router-dom";
import useFetch from "../../hooks/useFetch";
import { InstantSearch, Hits, Configure, InfiniteHits} from 'react-instantsearch-dom';
import { instantMeiliSearch } from '@meilisearch/instant-meilisearch';
import { useLocation } from 'react-router-dom';
import './search-results.scss';

const { searchClient } = instantMeiliSearch(
  'https://search.emtechindustrialsupply.com',
  'nPbdxpjJiSZyWKpJsXx45JnGhHczGAyEO4jjzstAXX0',
);


const SearchResults = () => {

  const [maxPrice, setMaxPrice] = useState(10000);
  const [sort, setSort] = useState(null);
  const [selectedBrands, setSelectedBrands] = useState([]);

  // Fetch brands data
  const { data: brandsData } = useFetch(`/brands`);

  

  const handleChange = (e) => {
    const value = e.target.value;
    const isChecked = e.target.checked;

    setSelectedBrands(
      isChecked
        ? [...selectedBrands, value]
        : selectedBrands.filter((item) => item !== value)
    );
  };

  const { search } = useLocation();
  const query = new URLSearchParams(search).get('query') || '';

  const getFilters = () => {
    const filters = [];
    if (selectedBrands.length > 0) {
      filters.push(`brand_id IN [${selectedBrands.join(', ')}]`);
    }
    if (maxPrice < 10000) {
      filters.push(`price <= ${maxPrice}`);
    }
    return filters.join(' AND ');
  };

  return (
    <div className="collection">
    <div className="title-container">
      <h1 className="title">Search Results: {query} </h1>
    </div>
    
    <div className="breadcrumbs">

      <Link className = 'text1' to="/">home</Link> /
      search-results: {query}
     
    </div>

    <div className="products">
      <div className="left">
        <div className="filterItem">
          <h2>Featured Brands</h2>
          {brandsData &&
            brandsData.map((item) => (
              <div className="inputItem" key={item.id}>
                <input
                  type="checkbox"
                  id={item.id}
                  value={item.id}
                  onChange={handleChange}
                />
                <label htmlFor={item.id}>
                  <p>{item.attributes.title}</p>
                </label>
              </div>
            ))}
        </div>
        <div className="filterItem">
          <h2>Filter by price</h2>
          <div className="inputItem">
            <span>0</span>
            <input
              type="range"
              min={0}
              max={10000}
              onChange={(e) => setMaxPrice(e.target.value)}
            />
            <span>{maxPrice}</span>
          </div>
        </div>
        <div className="filterItem">
          <h2>Sort by</h2>
          <div className="inputItem">
            <input
              type="radio"
              id="asc"
              value="asc"
              name="price"
              onChange={(e) => setSort("asc")}
            />
            <label htmlFor="asc">New</label>
          </div>
          <div className="inputItem">
            <input
              type="radio"
              id="desc"
              value="desc"
              name="price"
              onChange={(e) => setSort("desc")}
            />
            <label htmlFor="desc">Used</label>
          </div>
        </div>
      </div>
      <div className="right">
          <div className="search-results-container">
          <InstantSearch
            indexName="product"
            searchClient={searchClient}
          >
            <Configure hitsPerPage={10} query={query}
               filters={getFilters()}
               sortBy={sort === "asc" ? "product:price:asc" : sort === "desc" ? "product:price:desc" : undefined}
            />
          
            <InfiniteHits hitComponent={Hit} />
          </InstantSearch>
        </div>
      </div>
    </div>
  </div>




  );
};

const Hit = ({ hit }) => {

  const imageUrl = `https://api.emtechindustrialsupply.com${hit.img?.url}`;

  return (
    <li className="hits2" key={hit.id}>
      <a href={`/products/${hit.product_name}`} className="hit2-link">
        <img src={imageUrl} className="hit2-image" alt={hit.title} />
        <p className="hit2-title">{hit.title}</p>
        <p className="hit2-price">${hit.price}</p>
      </a>
    </li>
  );
};

export default SearchResults;