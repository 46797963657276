import React, { useState } from "react";
import "./product.scss";
import useFetch from "../../hooks/useFetch";
import { FaShoppingCart } from 'react-icons/fa';
import { useParams, Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { addToCart } from "../../redux/cartReducer";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Product = () => {
  const { product_name } = useParams();
  
  const [selectedImg, setSelectedImg] = useState("img");
  const [quantity, setQuantity] = useState(1);

  const dispatch = useDispatch();
  const { data, loading } = useFetch(`/products/${product_name}?populate=*`);
  const { data: productCat } = useFetch(`/products?filters[product_name][$eq]=${product_name}&populate=*`);
  const categoryTitle = productCat?.[0]?.attributes.categories?.data?.[0]?.attributes?.title || 'category';
  const subcategoryTitle = productCat?.[0]?.attributes.sub_categories?.data?.[0]?.attributes?.title || 'sub_category';

  const handleAddToCart = () => {
    dispatch(
      addToCart({
        id: data.id,
        title: data.attributes.title,
        desc: data.attributes.desc,
        price: data.attributes.price,
        img: data.attributes.img.data.attributes.url,
        quantity,
      })
    );
    toast.success('Item successfully added to cart!', {
      className: 'custom-toast-success',
    });
  };

  const isOutOfStock = data?.attributes?.status?.toLowerCase() === "out of stock";

  return (
    <div className="product-wrapper">
      <ToastContainer />
      {loading ? (
        "Stand By"
      ) : (
        <div className="breadcrumbs">
          <Link className="text1" to="/">home</Link> /
          <Link className="text2" to={`/categories/${categoryTitle}`}>{categoryTitle}</Link> /
          <Link className="text3" to={`/sub_categories/${subcategoryTitle}`}>{subcategoryTitle}</Link> /
          {product_name}
        </div>
      )}
      {loading ? (
        " Loading..."
      ) : (
        <div className="product">
          <div className="left">
            <div className="images">
              <img
                src={
                  (data?.attributes?.img?.data?.attributes?.url &&
                    process.env.REACT_APP_UPLOAD_URL +
                      data.attributes.img.data.attributes.url) ||
                  'https://upload.wikimedia.org/wikipedia/commons/1/14/No_Image_Available.jpg'
                }
                alt=""
                onClick={() => setSelectedImg("img")}
              />
              <img
                src={
                  (data?.attributes?.img2?.data?.attributes?.url &&
                    process.env.REACT_APP_UPLOAD_URL +
                      data.attributes.img2.data.attributes.url) ||
                  'https://upload.wikimedia.org/wikipedia/commons/1/14/No_Image_Available.jpg'
                }
                alt=""
                onClick={() => setSelectedImg("img2")}
              />
            </div>
            <div className="mainImg">
              <img
                src={
                  (selectedImg && data?.attributes[selectedImg]?.data?.attributes?.url &&
                    process.env.REACT_APP_UPLOAD_URL +
                      data.attributes[selectedImg].data.attributes.url) ||
                  'https://upload.wikimedia.org/wikipedia/commons/1/14/No_Image_Available.jpg'
                }
                alt=""
              />
            </div>
          </div>
          <div className="right">
            <p className="sku"><span>SKU:</span> { data?.attributes?.sku}</p>
            <h1 className="title">{data?.attributes?.title}</h1>
            <div className="product-details">
              <span className={`status ${isOutOfStock ? 'out-of-stock' : 'in-stock'}`}>
                {isOutOfStock ? (
                  <span className="status-text">Out of Stock: <span className="status-icon1">&#10005;</span></span>
                ) : (
                  <span className="status-text">In Stock: <span className="status-icon2">&#10003;</span></span>
                )}
              </span>
              <span className="condition"> <span>Condition:</span> {data?.attributes?.condition}</span>
            </div>
            <span className="price">${data?.attributes?.price}</span>
           
            <div className="quantity">
              <button
                onClick={() =>
                  setQuantity((prev) => (prev === 1 ? 1 : prev - 1))
                }
              >
                -
              </button>
              {quantity}
              <button onClick={() => setQuantity((prev) => prev + 1)}>+</button>
            </div>
            <button
              className={`add ${isOutOfStock ? 'disabled' : ''}`}
              onClick={handleAddToCart}
              disabled={isOutOfStock}
            >
              <FaShoppingCart /> ADD TO CART
            </button>
            <hr />
            <div className="info">
              <span>DESCRIPTION</span>
              <p>{data?.attributes?.desc}</p>
              <hr />
              <span>Delivery</span>
              <p>USA delivery by UPS express courier</p>
              <hr/> 
              <span>ADDITIONAL INFORMATION</span>
              <p>100% insured shipping: A guarantee for your purchases</p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Product;
