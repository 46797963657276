import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  products: [],
};

export const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    addToCart: (state, action) => {
      const existingItemIndex = state.products.findIndex((item) => item.id === action.payload.id);
      if (existingItemIndex !== -1) {
        // Item already exists in the cart, update its quantity
        state.products[existingItemIndex].quantity += action.payload.quantity;
      } else {
        // Item does not exist in the cart, add it
        state.products.push(action.payload);
      }
    },
    removeItem: (state, action) => {
      state.products = state.products.filter(item => item.id !== action.payload);
    },
    resetCart: (state) => {
      state.products = [];
    },
    updateQuantity: (state, action) => {
      const { productId, quantity } = action.payload;
      const existingItem = state.products.find(item => item.id === productId);
      if (existingItem) {
        existingItem.quantity = quantity;
      }
    },
  },
});

// Action creators are generated for each case reducer function
export const { addToCart, removeItem, resetCart, updateQuantity } = cartSlice.actions;

export default cartSlice.reducer;