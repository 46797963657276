import React, { useState, useEffect } from 'react';
import './terms-service.scss';

const TermsCondition = () => {
  

  return (
    <div className="terms-of-service">

      <div className='container'>
        <h1>Terms of Service</h1>
        <p className='paragraph'>
            Below are our Terms of Service, which outline a lot of legal goodies, 
            but the bottom line is it’s our aim to always take care of  you, 
            as a customer. We’ve included many of these terms to legally protect ourselves, but if you have an issue, 
            always feel free to email us at aarzolac@gmail.com and we’ll do 
            our best to resolve it in a fair and timely fashion.
        </p>
        <ul>
          <li>
            <h6>Use of Our Website:</h6>
            <p>You may use our website for lawful purposes only. You agree not to engage in any activity that violates local, national, or international laws and regulations. We reserve the right to terminate or suspend your access to our website if we believe that you have violated these terms.</p>
          </li>
          <li>
            <h6>Intellectual Property:</h6>
            <p>All content on our website, including text, graphics, logos, images, and software, is the property of Emtech Industrial Supply or its licensors and is protected by copyright and other intellectual property laws. You may not reproduce, modify, distribute, or republish any content without our prior written consent.</p>
          </li>
          <li>
            <h6>Account Registration:</h6>
            <p>In order to access certain features of our website, you may be required to register for an account. You agree to provide accurate and complete information when creating your account and to keep your login credentials confidential. You are responsible for all activities that occur under your account.</p>
          </li>
          <li>
            <h6>Privacy Policy:</h6>
            <p>Your use of our website is also governed by our Privacy Policy, which outlines how we collect, use, disclose, and protect your personal information. By using our website, you consent to the terms of our Privacy Policy.</p>
          </li>
          <li>
            <h6>Changes to Terms:</h6>
            <p>We reserve the right to modify or update these Terms of Service at any time without prior notice. Any changes will be effective immediately upon posting on our website. Your continued use of our website after the posting of revised terms constitutes your acceptance of the changes.</p>
          </li>
          <li>
            <h6>Limitation of Liability:</h6>
            <p>Emtech Industrial Supply shall not be liable for any direct, indirect, incidental, special, or consequential damages arising out of or in any way connected with your use of our website or services, even if we have been advised of the possibility of such damages.</p>
          </li>
          <li>
            <h6>Governing Law:</h6>
            <p>These Terms of Service shall be governed by and construed in accordance with the laws of Texas, without regard to its conflict of law provisions. Any disputes arising under these terms shall be resolved exclusively by the courts of Texas.</p>
          </li>
          <li>
            <h6>Contact Us:</h6>
            <p>If you have any questions or concerns about our Terms of Service, please contact us at aarzolac@gmail.com. We are committed to providing a safe and enjoyable experience for all of our users.</p>
          </li>
        </ul>

      </div>

    
      
    </div>
  );
};

export default TermsCondition;