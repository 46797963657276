import React from "react";
import "./footer.scss";

const Footer = () => {
  return (
    <div className="footer">
      <hr/>
      <div className="top">
        <div className="item">
          <h1>Information</h1>
          <span> 2000 Oriole Ave, McAllen, Texas USA</span>
          <span>aarzolac@gmail.com</span>
          <span>(956)-330-2103</span>
        </div>
        <div className="item">
          <h1>Customer Support</h1>
          <span> <a href="/contact">Contact Us</a></span>
          <span><a href="/about-us">About Us</a></span>
          <span> <a href="/terms&conditions">Terms of Service</a></span>
        </div>
        <div className="item">
          <h1>Policy</h1>
          <span><a href="/shipping-policy">Shipping Policy</a></span>
          <span><a href="/refund-policy">Refund Policy</a></span>
          <span><a href="/privacy-policy">Privacy Policy</a></span>
      
      </div>
      </div>
      <div className="bottom">
        <div className="left">
          <span className="logo">Emtech Industrial Supply</span>
          
        </div>
        <div className="right">
          <img src="/images/payment.png" alt="" />
        </div>
      </div>
      <div className='bottom2'>
        <span className="copyright">
           Emtech Industrial Supply © Copyright 2024. All Rights Reserved
          </span>
      </div>
    </div>
  );
};

export default Footer;