import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { resetCart } from '../../redux/cartReducer'; // Adjust path as needed
import './order.scss';

const Order = () => {
  const dispatch = useDispatch();

  // Assuming you want to reset the cart immediately when Order component mounts
  useEffect(() => {
    dispatch(resetCart());
  }, [dispatch]);

  return (
    <div className="thank-you-page">
      <h1>Thank You for Your Purchase!</h1>
      <p>Your payment was successful. You will receive a confirmation email shortly.</p>
      <a href='/'><button>Continue Shopping</button></a>
    </div>
  );
};

export default Order;