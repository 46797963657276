import React from "react";
import { createBrowserRouter, Outlet, RouterProvider} from "react-router-dom";
import Header from './components/header/header';
import Footer from './components/footer/footer';
import Home from './pages/home/home';
import About from './pages/about us/about us';
import Contact from './pages/contact/contact';
import TermsCondition from "./pages/terms-service/terms-service";
import RefundPolicy from "./pages/refund-policy/refund-policy";
import ShippingPolicy from "./pages/shipping-policy/shipping-policy";
import PrivacyPolicy from './pages/privacy-policy/privacy-policy';
import SearchResults from './pages/search-results/search-results';
import Collection1 from './pages/categories/categories';
import Collection2 from './pages/sub_categories/sub_categories';
import Product from './pages/product/product';
import Cart from './pages/cart/cart';
import NotFound from'./pages/not-found/not-found';
import Order from './pages/order/order';


const Layout = () => {
  return (
    <div>
      <Header />
      <Outlet />
      <Footer />
    </div>
  );
};

const router = createBrowserRouter([
  {
    path: '/',
    element: <Layout />,
    children: [
      {
        path: '/',
        element: <Home />,
      },
      {
        path: '/about-us',
        element: <About />,
      },
      {
        path: '/contact',
        element: <Contact />,
      },
      {
        path: '/terms&conditions',
        element: <TermsCondition />,
      },
      {
        path: '/refund-policy',
        element: <RefundPolicy />,
      },
      {
        path: '/shipping-policy',
        element: <ShippingPolicy />,
      },
      {
        path: '/privacy-policy',
        element: <PrivacyPolicy />,
      },
      {
        path: '/search-results',
        element: <SearchResults />
      },
      {
        path: '/categories/:title',
        element: <Collection1/>
      },
      {
        path: '/sub_categories/:title',
        element: <Collection2/>
      },
      {
        path: '/products/:product_name',
        element: <Product/>,
      },
      {
        path: '/cart',
        element: <Cart />,
      },
      {
        path: '/order',
        element: <Order/>,
      },
      {
        path: '*', 
        element: <NotFound />, 
      },
     
    ],
  },
]);

function App() {

  return (
    <div className="font-bodyFont">
      <RouterProvider router={router} />
    </div>
  );
}

export default App;