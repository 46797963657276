import React from "react";
import "./categories.scss";
import { Link } from "react-router-dom";

const Categories = () => {
  return (
    <div className="categories">
      <div className="col">
        <div className="row">
          <img
            src="images/07_11_2023_15_47_41_SR3B261BD_Schneider_Electric.jpg"
            alt=""
          />
          <button>
            <Link className="link" to="/sub_categories/plc">
              PLC
            </Link>
          </button>
        </div>
        <div className="row">
          <img
            src="/images/51pW7cpmo5L._SY445_SX342_QL70_FMwebp_.webp"
            alt=""
          />
          <button>
            <Link to="/sub_categories/cylinders" className="link">
              Cylinders
            </Link>
          </button>
        </div>
      </div>
      <div className="col">
        <div className="row">
          {" "}
          <img
            src="/images/A2H80wmD8z0vQNB0q1OQHVzWQsu2cloQhJQh62qvyzLFQ3EoR5T6BesR7fHb.jpg"
            alt=""
          />
          <button>
            <Link to="/sub_categories/power-supplies" className="link">
              Power Supplies
            </Link>
          </button>
        </div>
      </div>
      <div className="col col-l">
        <div className="row">
          <div className="col">
            <div className="row">
              <img
                src="/images/fa1dc24ad0ff79de53e8fec26d0ed7cef1765484_1000x1000.webp"
                alt=""
              />
              <button>
                <Link to="/sub_categories/multimeters" className="link">
                  Multimeters
                </Link>
              </button>
            </div>
          </div>
          <div className="col">
            <div className="row">
              {" "}
              <img
                src="/images/SS-KH_HandToolsBusinessesShouldHave_KH-HRO.jpg"
                alt=""
              />
              <button>
                <Link to="/sub_categories/hand-tools" className="link">
                  Hand Tools
                </Link>
              </button>
            </div>
          </div>
        </div>
        <div className="row">
          <img
            src="/images/Fluke_2625A_Hydra_Data_Logger-1.jpg"
            alt=""
          />
          <button>
            <Link to="/sub_categories/dataloggers" className="link">
              Dataloggers
            </Link>
          </button>
        </div>
      </div>
    </div>
  );
};

export default Categories;