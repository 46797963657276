import React from "react";
import "./list.scss";
import ProductCard from "../productCard/productCard";
import useFetch from "../../hooks/useFetch";

const List = ({ brands, maxPrice, sort, title }) => {

  const { data, loading, error } = useFetch(
    `/products?populate=*&[filters][categories][title][$eq]=${title}${brands.map(
      (item) =>`&[filters][brands][id][$eq]=${item}`
    )}&[filters][price][$lte]=${maxPrice}`
  );

  console.log(data);

  return (
    <div className="list">
      {loading
        ? "Loading..."
        : error
        ? "No items based on this description"
        : data?.map((item) => <ProductCard item={item} key={item.id} />)}
    </div>
  );
};

export default List;